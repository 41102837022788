import React from 'react';
interface NotificationIconProps extends React.SVGProps<SVGSVGElement> {}

const NotificationIcon: React.FC<NotificationIconProps> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none">
  <path d="M6.44555 15.3644C6.3543 17.1094 6.4593 18.9669 4.90055 20.1357C4.54218 20.4039 4.25137 20.752 4.05129 21.1524C3.85121 21.5528 3.74737 21.9943 3.74805 22.4419C3.74805 23.6882 4.72555 24.7507 5.99805 24.7507H23.9981C25.2706 24.7507 26.248 23.6882 26.248 22.4419C26.248 21.5344 25.8205 20.6794 25.0955 20.1357C23.5368 18.9669 23.6418 17.1094 23.5505 15.3644C23.4381 13.172 22.4879 11.1066 20.8964 9.59456C19.3048 8.08256 17.1933 7.2395 14.9981 7.2395C12.8028 7.2395 10.6913 8.08256 9.09975 9.59456C7.50818 11.1066 6.55804 13.172 6.44555 15.3644Z" stroke="black" strokeWidth="2.08077" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M18.748 24.7505C18.748 25.7451 18.353 26.6989 17.6497 27.4021C16.9464 28.1054 15.9926 28.5005 14.998 28.5005C14.0035 28.5005 13.0497 28.1054 12.3464 27.4021C11.6431 26.6989 11.248 25.7451 11.248 24.7505M13.123 4.90674C13.123 5.94174 13.963 7.25049 14.998 7.25049C16.033 7.25049 16.873 5.94174 16.873 4.90674C16.873 3.87174 16.033 3.50049 14.998 3.50049C13.963 3.50049 13.123 3.87174 13.123 4.90674Z" stroke="black" strokeWidth="2.08077" strokeLinecap="round" strokeLinejoin="round"/>
  <circle cx="23" cy="7.99982" r="8" fill="#C7000A"/>
  <path d="M21.45 4.74982V3.70982H23.53V10.9998H22.38V4.74982H21.45Z" fill="white"/>
  </svg>
);

export default NotificationIcon;

