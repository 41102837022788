import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ProductSliceState {
    loading: boolean;
    error: Error | null;
    list: any[];
    count: number;
}

const initialState: ProductSliceState = {
    loading: false,
    error: null,
    list: [],
    count: 0
}

const ProductSliceState = createSlice({
    name: 'productdetails',
    initialState,
    reducers: {
        startLoading: (state: ProductSliceState) => {
            state.loading = true;
        },
        stopLoading: (state: ProductSliceState) => {
            state.loading = false;
        },
        fetchedProductDetails: (state: ProductSliceState, action: PayloadAction<{ list: any[], count: number}>) => {
            state.list = action.payload.list;
            state.count = action.payload.count;
        },
        changeStatusProductDetails: (state: ProductSliceState, action: PayloadAction<{ id: number, isActive: boolean}>) => {
            state.list = state.list.map((list) => {
                if(list.id === action.payload.id) {
                    return {
                        ...list,
                        isActive: action.payload.isActive
                    }
                } else {
                    return list;
                }
            })
        } 
    }
})

export const productSliceActions = ProductSliceState.actions;
export default ProductSliceState.reducer;