import React from "react";
import unAuthorized from "../../assets/images/unauthorized.png";
import "./unauthorized.scss";
import { useNavigate } from "react-router-dom";
import Button from "../../components/button/button";

const Unauthorized: React.FC = () => {
    const navigate = useNavigate();

    return (
        <div className="unauthorized-container">
            <div className="unauthorized-section">
                <img src={unAuthorized} alt="Page Not Found" className="unauthorized-image" />
            </div>
            <div className="unauthorized-text">
                <p>
                You are not authorize
                </p>
            </div>
                <Button
                    variant="filled"
                    size="medium"
                    className="mt-3"
                    type="button"
                    onClick={() => navigate('/')}
                >
                    Back To Home
                </Button>
        </div>
    );
};

export default Unauthorized;
