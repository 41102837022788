import './fallback.scss';

const Fallback = () => {
    return (
        <>
            Need to do css
        </>
    )
}

export default Fallback;