import { ApiClient } from './web.service';
import { APIPATH } from '../utils/constants';


const apiClient = () => {
  return new ApiClient();
};

export const fetchUserDropDown = async (payload = {}) => {
  return apiClient().post(`${APIPATH.USER}/get`, payload);
};

export const fetchUserGrid = async (payload: any) => {
 return apiClient().post(`${APIPATH.USER}/list`, payload);
};

export const createUser = async (payload: any) => {
  return apiClient().post(APIPATH.USER, payload);
};

export const updateUser = async (id: number, payload: any) => {
  return apiClient().put(`${APIPATH.USER}/${id}`, payload);
};

export const fetchByIdUser = async (id: number) => {
  return apiClient().get(`${APIPATH.USER}/${id}`);
};

export const deleteUser = async (id: number) => {
  return apiClient().delete(`${APIPATH.USER}/${id}`);
};

export const fetchLoginUser = async () => {
  return apiClient().get(`${APIPATH.USER}/get`);
};

