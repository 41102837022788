import { useEffect, useState } from "react";

const useIsMobileViewHooks = () => {
    const [isMobileView, setIsMobileView] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 768);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return {
        isMobileView
    }
} 

export default useIsMobileViewHooks;