import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ModuleSliceState {
    loading: boolean;
    error: Error | null;
    list: any[];
    count: number;
}

const initialState: ModuleSliceState = {
    loading: false,
    error: null,
    list: [],
    count: 0
}

const moduleSlice = createSlice({
    name: 'module',
    initialState,
    reducers: {
        startLoading: (state: ModuleSliceState) => {
            state.loading = true;
        },
        stopLoading: (state: ModuleSliceState) => {
            state.loading = false;
        },
        fetchedModule: (state: ModuleSliceState, action: PayloadAction<{ list: any[], count: number}>) => {
            state.list = action.payload.list;
            state.count = action.payload.count;
        },
        // deleteModule: (state: ModuleSliceState, action: PayloadAction<{ id: number, isActive: boolean}>) => {
        //     state.list = state.list.map((list) => {
        //         if(list.id === action.payload.id) {
        //             return {
        //                 ...list,
        //                 isActive: action.payload.isActive
        //             }
        //         } else {
        //             return list;
        //         }
        //     })
        // } 
        changeStatusModule: (state: ModuleSliceState, action: PayloadAction<{ id: number, isActive: boolean}>) => {
            state.list = state.list.map((list) => {
                if(list.id === action.payload.id) {
                    return {
                        ...list,
                        isActive: action.payload.isActive
                    }
                } else {
                    return list;
                }
            })
        } 
    }
})

export const moduleSliceActions = moduleSlice.actions;
export default moduleSlice.reducer;