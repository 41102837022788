import React from "react";
import pageNotFound from "../../assets/images/pagenotfound.png";
import "./pagenotfound.scss";
import { useNavigate } from "react-router-dom";
import Button from "../../components/button/button";

const PageNotFound: React.FC = () => {
    const navigate = useNavigate();

    return (
        <div className="pagenotfound-container">
            <div className="notfound-section">
                <img src={pageNotFound} alt="Page Not Found" className="notfound-image" />
            </div>
            <div className="text-section">
                <p>
                    The page you're looking for doesn't exist. It may have been moved, deleted, or perhaps the URL was typed incorrectly.<br />
                    Please make sure the web address is correct.<br />
                    You can return to the previous page by clicking the "Back" button in your browser.
                </p>
            </div>
                <Button
                    variant="filled"
                    size="medium"
                    className="mt-3"
                    type="button"
                    onClick={() => navigate('/')}
                >
                    Back To Home
                </Button>
        </div>
    );
};

export default PageNotFound;
