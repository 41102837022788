import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface RoleSliceState {
  loading: boolean;
  error: Error | null;
  list: any[];
  count: number;
}

const initialState: RoleSliceState = {
  loading: false,
  error: null,
  list: [],
  count: 0,
};

const roleSlice = createSlice({
  name: "role",
  initialState,
  reducers: {
    startLoading: (state: RoleSliceState) => {
      state.loading = true;
    },
    stopLoading: (state: RoleSliceState) => {
      state.loading = false;
    },
    fetchedModule: (
      state: RoleSliceState,
      action: PayloadAction<{ list: any[]; count: number }>
    ) => {
      state.list = action.payload.list;
      state.count = action.payload.count;
    },
    deleteModule: (
      state: RoleSliceState,
      action: PayloadAction<{ id: number; isActive: boolean }>
    ) => {
      state.list = state.list.map((list) => {
        if (list.id === action.payload.id) {
          return {
            ...list,
            isActive: action.payload.isActive,
          };
        } else {
          return list;
        }
      });
    },
  },
});

export const moduleSliceActions = roleSlice.actions;
export default roleSlice.reducer;
