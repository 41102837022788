import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

const Loader: React.FC = () => {
  const loading = useSelector((state: RootState) => state.app.loading);

  if (!loading) return null;

  return (
    <div className="loading-overlay  loading">
      <div className="d1" />
      <div className="d2" />
    </div>
  );
};

export default Loader;