import { GridColDef } from "@mui/x-data-grid";

export const DEBOUNCE_THRESHOLD = 500;
// export const PAGE_SIZE = 10;
export const DISPLAY_DATE_FORMAT = "DD/MM/yyyy";
export const DISPLAY_TIME = "hh:mm a";
export const API_DATE_FORMAT = "yyyy-MM-dd";
export const TOKEN_KEY = "clientToken";
export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const PHONE_REGEX = /^\d{10}$/i;
export const OTP_REGEX = /^\d{6}$/i;
export const DIGIT_REGEX = /^\d*(\.\d{0,2})?$/i; // /^[0-9]+([\.,][0-9]+)?/i;
export const ALPHABETS_REGEX = /^[a-zA-Z\s]+$/;
export const DIGIT__ALPHA_REGEX = /^(?=.*\d|[a-zA-Z]|-)[a-zA-Z\d-]+$/;
export const GST_REGEX =
  /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/i;
export const PAN_REGEX = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
export const IFSC_REGEX = /^[A-Z]{4}0[A-Z0-9]{6}$/i;
export const TAN_REGEX = /^[A-Z]{4}[0-9]{5}[A-Z]{1}$/;
export const CIN_REGEX =/^([LUu]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$/;
export const SWIFTCODE_REGEX =/^[A-Z]{4}[A-Z]{2}[A-Z0-9]{2}([A-Z0-9]{3})?$/
export const UPI_REGEX = /^[\w.-]+@[\w.-]+$/i; // /^[a-zA-Z0-9.-]{2, 256}@[a-zA-Z][a-zA-Z]{2, 64}$/i;
export const ENTER_KEY_CODE = 13;
export const DEFAULT_PAGING = {
    startPage: 1,
    pageSize: 10,
    orderDirection: "desc",
    maxPerPage: 100,
  },
  PAGING = {
    orderDirection: "asc",
  };

export const DEV_API_URL = process.env.REACT_APP_DEV_API_ENDPOINT_URL || "";
export const PROD_API_URL = process.env.REACT_APP_API_ENDPOINT_URL || "";
export const APIPATH = {
  ACTIVITY: "activity",
  DASHBOARD: "dashboard",
  LOGIN: "auth/login-via-phone",
  VERIFYOTP: "auth/verify-otp",
  COMPANY: "company",
  USER: "user",
  USERMAP: "usermap",
  ROLE: "role",
  RANK: "rank",
  SUBSCRIPTION: "subscription",
  DEMO: "demo",
  FOLLOWUP: "followup",
  ORDER: "order",
  SETTING: "setting",
  MODULE: "module",
  RIGHT: "right",
  CATEGORY: "category",
  SUBCATEGORY: "subcategory",
  PRODUCT:'product',
  MASTER: "master",
  STOCKDEATILS: "stock",
  STOCKHISTORY: "stock-history",
  MATERIALSTOCKDETAILS: "material-stock",
  MATERIAL: "material",
  MATERIALSTOCKHISTORY: "material-stock-history",
  CUSTOMER: "customer",
  PROFORMAINVOICE: "profroma-invoice",
  INVOICEDETAILS: "invoice",
  PURCHASEINVOICE: "purchase",
  FAQ: "faq",
};

