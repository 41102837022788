import { redirect } from "react-router-dom";
import { DEV_API_URL, PROD_API_URL, TOKEN_KEY } from "../constants";

export const setAuthToken = (token: string) => {
    localStorage.setItem(TOKEN_KEY, token);
};

export const getAuthToken = () => {
    const value = localStorage.getItem(TOKEN_KEY);
    if (value) {
        return true;
    }
    return false;
};

export const removeStorageToken = () => {
    localStorage.clear();
};

export const listPayload = (
    page: number,
    where: any = {},
    rows: number = 10,
    pagination: any = {},
    descending: boolean = true
) => {
    return {
        where: {
            isDeleted: false,
            ...where,
        },
        pagination: {
            sortBy: "createdAt",
            descending: descending,
            rows: rows,
            page: page + 1,
            ...pagination,
        },
    };
};

export const generateSlug = (value: string): string => {
    return String(value).toLowerCase().replace(/[^\w ]+/g, '').replace(/ +/g, '-');
};

export const generateCategorySlug = (value: string): string => {
    return String(value).toLowerCase().replace(/[^\w ]+/g, '').replace(/ +/g, '_');
};

export const generatePassword = (value: string): string => {
    return String(value).toLowerCase().replace(/[^\w ]+/g, '');
}

const baseUrl = window?.location.host.includes('localhost') ? DEV_API_URL : PROD_API_URL;
const apiUrl = `${baseUrl}`;
const modifiedapiUrl = apiUrl.replace(/\/api\/?$/, '');
const formattedApiUrl = modifiedapiUrl //modifiedapiUrl.endsWith('/') ? modifiedapiUrl : `${modifiedapiUrl}/`;

export const imagePath = (path: string): string => {
    return `${formattedApiUrl}${path}`;
};

export const ProductImagePath = (): string => {
    return `${formattedApiUrl}`;
};

export const checkIsAuthenticated = () => {
    const value = getAuthToken();
    if (!value) {
        return redirect('/login');
    }
    return true;
};