import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface CategorySliceState {
    loading: boolean;
    error: Error | null;
    list: any[];
    count: number;
}

const initialState: CategorySliceState = {
    loading: false,
    error: null,
    list: [],
    count: 0
}

const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {
        startLoading: (state: CategorySliceState) => {
            state.loading = true;
        },
        stopLoading: (state: CategorySliceState) => {
            state.loading = false;
        },
        fetchedCategory: (state: CategorySliceState, action: PayloadAction<{ list: any[], count: number}>) => {
            state.list = action.payload.list;
            state.count = action.payload.count;
        },
        changeStatusCategory: (state: CategorySliceState, action: PayloadAction<{ id: number, isActive: boolean}>) => {
            state.list = state.list.map((list) => {
                if(list.id === action.payload.id) {
                    return {
                        ...list,
                        isActive: action.payload.isActive
                    }
                } else {
                    return list;
                }
            })
        } 
     
    }
})

export const categorySliceActions = categorySlice.actions;
export default categorySlice.reducer;