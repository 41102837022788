import React, { lazy, Suspense } from "react";
import {
    Outlet,
    Navigate,
    RouterProvider,
    createBrowserRouter,
} from 'react-router-dom';

import Layout from "../core/layout";

import { checkIsAuthenticated, getAuthToken } from "../utils/helpers/intex";
import ProtectedRoute from "./hooks/protectedRoute";
import PageNotFound from "../pages/pagenotfound/pagenotfound";
import Unauthorized from "../pages/unauthorized/unauthorized";


const DashboardPage = lazy(() => import('../pages/dashboard'));
const LoginPage = lazy(() => import('../pages/login'));
const RolePage = lazy(() => import('../pages/role'));
const CompanyPage = lazy(() => import('../pages/company'));
const UserPage = lazy(() => import('../pages/user'));
const AddEditRolePage = lazy(() => import('../pages/role/component/addEditRole'));
const ModulePage = lazy(() => import('../pages/module'));
const AddEditModulePage = lazy(() => import('../pages/module/component/addEditModule'));
const AddEditCompanyPage = lazy(() => import('../pages/company/component/addEditCompany'));
const AddEditUserPage = lazy(() => import('../pages/user/component/addEditUser'));
const RightPage = lazy(() => import('../pages/right'));
const CategoryPage = lazy(() => import('../pages/category'));
const SettingPage = lazy(() => import('../pages/setting'));
const AddEditCategoryPage = lazy(() => import('../pages/category/component/addEditCategory'));
const SubCategoryPage = lazy(() => import('../pages/subcategory'));
const AddEditSettingPage = lazy(() => import('../pages/setting/component/addEditSetting'));
const AddEditSubCategoryPage = lazy(() => import('../pages/subcategory/component/addEditSubCategory'));
const MasterDetailsPage = lazy(() => import('../pages/materdetails'));
const AddEditMasterDetailsPage = lazy(() => import('../pages/materdetails/component/addEditMasterDetails'));
const StockDetailsPage = lazy(() => import('../pages/stockdetails'));
const AddEditStockDetailsPage = lazy(() => import('../pages/stockdetails/component/addEditStockDetails'));
const StockHistoryPage = lazy(() => import('../pages/stockhistory'));
const AddEditStockHistoryPage = lazy(() => import('../pages/stockhistory/component/addEditStockHistory'));
const MaterialStockDetailsPage = lazy(() => import('../pages/materialstockdetails'));
const AddEditMaterialStockDetailsPage = lazy(() => import('../pages/materialstockdetails/component/addEditMaterialStockDetails'));  
const MaterialPage = lazy(() => import('../pages/material'));
const AddEditMaterialPage = lazy(() => import('../pages/material/component/addEditMaterial'));    
const MaterialStockHistoryPage = lazy(() => import('../pages/materialstockhistory'));
const AddEditMaterialStockHistoryPage = lazy(() => import('../pages/materialstockhistory/component/addEditMaterialStockHistory'));  
const CustomerPage = lazy(() => import('../pages/customer'));
const AddEditCustomerPage = lazy(() => import('../pages/customer/component/addEditCustomer'));  
const ProformaInvoicePage = lazy(() => import('../pages/profomainvoice'));
const AddEditProformaInvoicePage = lazy(() => import('../pages/profomainvoice/component/addEditProformaInvoice'));   
const InvoiceDetailsPage = lazy(() => import('../pages/invoicedetails'));
const AddEditInvoiceDetailsPage = lazy(() => import('../pages/invoicedetails/component/addEditInvoiceDetails'));     
const PurchaseInvoicePage = lazy(() => import('../pages/purchaseinvoice'));
const AddEditPurchaseInvoicePage = lazy(() => import('../pages/purchaseinvoice/component/addEditPurchaseInvoice')); 
const FaqPage = lazy(() => import('../pages/faq')); 
const AddEditFaqPage = lazy(() => import('../pages/faq/component/addEditFaq'));  
const ProductPage = lazy(() => import('../pages/product'));
const AddEditProductPage = lazy(() => import('../pages/product/component/addEditProduct'));

const Routes = () => {
    const routes = createBrowserRouter([
        {
            path: '/',
            element: (
                <Layout>
                    <Suspense>
                        <ProtectedRoute Component={<DashboardPage/>}/>
                    </Suspense>
                </Layout>
            ),
            loader: checkIsAuthenticated
        },
        {
            path: '/analytics',
            element: (
                <Layout>
                    <Suspense>
                        <ProtectedRoute Component={<AddEditRolePage/>}/>
                    </Suspense>
                </Layout>
            ),
            loader: checkIsAuthenticated
        },
        {
            path: '/module',
            element: (
                <Layout>
                    <Suspense>
                        <Outlet/>
                    </Suspense>
                </Layout>
            ),
            loader: checkIsAuthenticated,
            children: [
                {
                    index: true,
                    element: <ProtectedRoute Component={<ModulePage />} />
                },
                {
                    path: '/module/:mode',
                    element: <ProtectedRoute Component={<AddEditModulePage />} />
                },
                {
                    path: '/module/:mode/:id',
                    element: <ProtectedRoute Component={<AddEditModulePage />} />
                }
            ]
        },
        {
            path: '/role',
            element: (
                <Layout>
                    <Suspense>
                        <Outlet/>
                    </Suspense>
                </Layout>
            ),
            loader: checkIsAuthenticated,
            children: [
                {
                    index: true,
                    element: <ProtectedRoute Component={<RolePage />} />
                },
                {
                    path: '/role/:mode',
                    element: <ProtectedRoute Component={<AddEditRolePage />} />
                },
                {
                    path: '/role/:mode/:id',
                    element: <ProtectedRoute Component={<AddEditRolePage />} />
                }
            ]
        },
        {
            path: '/company',
            element: (
                <Layout>
                    <Suspense>
                        <Outlet/>
                    </Suspense>
                </Layout>
            ),
            loader: checkIsAuthenticated,
            children: [
                {
                    index: true,
                    element: <ProtectedRoute Component={<CompanyPage />} />
                },
                {
                    path: '/company/:mode',
                    element: <ProtectedRoute Component={<AddEditCompanyPage />} />
                },
                {
                    path: '/company/:mode/:id',
                    element: <ProtectedRoute Component={<AddEditCompanyPage />} />
                }
            ]
        },
        {
            path: '/user',
            element: (
                <Layout>
                    <Suspense>
                        <Outlet/>
                    </Suspense>
                </Layout>
            ),
            loader: checkIsAuthenticated,
            children: [
                {
                    index: true,
                    element: <ProtectedRoute Component={<UserPage />} />
                },
                {
                    path: '/user/:mode',
                    element: <ProtectedRoute Component={<AddEditUserPage />} />
                },
                {
                    path: '/user/:mode/:id',
                    element: <ProtectedRoute Component={<AddEditUserPage />} />
                }
            ]
        },
        {
            path: '/right',
            element: (
                <Layout>
                    <Suspense>
                        <Outlet/>
                    </Suspense>
                </Layout>
            ),
            loader: checkIsAuthenticated,
            children: [
                {
                    index: true,
                    element: <ProtectedRoute Component={<RightPage/>} />
                }
            ]
        },
        {
            path: '/setting',
            element: (
                <Layout>
                    <Suspense>
                        <Outlet/>
                    </Suspense>
                </Layout>
            ),
            loader: checkIsAuthenticated,
            children: [
                {
                    index: true,
                    element: <ProtectedRoute Component={<SettingPage />} />
                },
                {
                    path: '/setting/:mode',
                    element: <ProtectedRoute Component={<AddEditSettingPage />} />
                },
                {
                    path: '/setting/:mode/:id',
                    element: <ProtectedRoute Component={<AddEditSettingPage />} />
                }
            ]
        },
        {
            path: '/category',
            element: (
                <Layout>
                    <Suspense>
                        <Outlet/>
                    </Suspense>
                </Layout>
            ),
            loader: checkIsAuthenticated,
            children: [
                {
                    index: true,
                    element: <ProtectedRoute Component={<CategoryPage />} />
                },
                {
                    path: '/category/:mode',
                    element: <ProtectedRoute Component={<AddEditCategoryPage />} />
                },
                {
                    path: '/category/:mode/:id',
                    element: <ProtectedRoute Component={<AddEditCategoryPage />} />
                }
            ]
        },
        {
            path: '/sub-category',
            element: (
                <Layout>
                    <Suspense>
                        <Outlet/>
                    </Suspense>
                </Layout>
            ),
            loader: checkIsAuthenticated,
            children: [
                {
                    index: true,
                    element: <ProtectedRoute Component={<SubCategoryPage />} />
                },
                {
                    path: '/sub-category/:mode',
                    element: <ProtectedRoute Component={<AddEditSubCategoryPage />} />
                },
                {
                    path: '/sub-category/:mode/:id',
                    element: <ProtectedRoute Component={<AddEditSubCategoryPage />} />
                }
            ]
        },
        {
            path: '/product',
            element: (
                <Layout>
                    <Suspense>
                        <Outlet/>
                    </Suspense>
                </Layout>
            ),
            loader: checkIsAuthenticated,
            children: [
                {
                    index: true,
                    element: <ProtectedRoute Component={<ProductPage />} />
                },
                {
                    path: '/product/:mode',
                    element: <ProtectedRoute Component={<AddEditProductPage />} />
                },
                {
                    path: '/product/:mode/:id',
                    element: <ProtectedRoute Component={<AddEditProductPage />} />
                }
            ]
        },
        {
            path: '/master-details',
            element: (
                <Layout>
                    <Suspense>
                        <Outlet/>
                    </Suspense>
                </Layout>
            ),
            loader: checkIsAuthenticated,
            children: [
                {
                    index: true,
                    element: <ProtectedRoute Component={<MasterDetailsPage />} />
                },
                {
                    path: '/master-details/:mode',
                    element: <ProtectedRoute Component={<AddEditMasterDetailsPage />} />
                },
                {
                    path: '/master-details/:mode/:id',
                    element: <ProtectedRoute Component={<AddEditMasterDetailsPage />} />
                }
            ]
        },
        {
            path: '/stock-details',
            element: (
                <Layout>
                    <Suspense>
                        <Outlet/>
                    </Suspense>
                </Layout>
            ),
            loader: checkIsAuthenticated,
            children: [
                {
                    index: true,
                    element: <ProtectedRoute Component={<StockDetailsPage />} />
                },
                {
                    path: '/stock-details/:mode',
                    element: <ProtectedRoute Component={<AddEditStockDetailsPage />} />
                },
                {
                    path: '/stock-details/:mode/:id',
                    element: <ProtectedRoute Component={<AddEditStockDetailsPage />} />
                }
            ]
        },
        {
            path: '/stock-history',
            element: (
                <Layout>
                    <Suspense>
                        <Outlet/>
                    </Suspense>
                </Layout>
            ),
            loader: checkIsAuthenticated,
            children: [
                {
                    index: true,
                    element: <ProtectedRoute Component={<StockHistoryPage />} />
                },
                {
                    path: '/stock-history/:mode',
                    element: <ProtectedRoute Component={<AddEditStockHistoryPage />} />
                },
                {
                    path: '/stock-history/:mode/:id',
                    element: <ProtectedRoute Component={<AddEditStockHistoryPage />} />
                }
            ]
        },
        {
            path: '/material-stock-details',
            element: (
                <Layout>
                    <Suspense>
                        <Outlet/>
                    </Suspense>
                </Layout>
            ),
            loader: checkIsAuthenticated,
            children: [
                {
                    index: true,
                    element: <ProtectedRoute Component={<MaterialStockDetailsPage />} />
                },
                {
                    path: '/material-stock-details/:mode',
                    element: <ProtectedRoute Component={<AddEditMaterialStockDetailsPage />} />
                },
                {
                    path: '/material-stock-details/:mode/:id',
                    element: <ProtectedRoute Component={<AddEditMaterialStockDetailsPage />} />
                }
            ]
        },
        {
            path: '/material',
            element: (
                <Layout>
                    <Suspense>
                        <Outlet/>
                    </Suspense>
                </Layout>
            ),
            loader: checkIsAuthenticated,
            children: [
                {
                    index: true,
                    element: <ProtectedRoute Component={<MaterialPage />} />
                },
                {
                    path: '/material/:mode',
                    element: <ProtectedRoute Component={<AddEditMaterialPage />} />
                },
                {
                    path: '/material/:mode/:id',
                    element: <ProtectedRoute Component={<AddEditMaterialPage />} />
                }
            ]
        },
        {
            path: '/material-stock-history',
            element: (
                <Layout>
                    <Suspense>
                        <Outlet/>
                    </Suspense>
                </Layout>
            ),
            loader: checkIsAuthenticated,
            children: [
                {
                    index: true,
                    element: <ProtectedRoute Component={<MaterialStockHistoryPage />} />
                },
                {
                    path: '/material-stock-history/:mode',
                    element: <ProtectedRoute Component={<AddEditMaterialStockHistoryPage />} />
                },
                {
                    path: '/material-stock-history/:mode/:id',
                    element: <ProtectedRoute Component={<AddEditMaterialStockHistoryPage />} />
                }
            ]
        },
        {
            path: '/customer',
            element: (
                <Layout>
                    <Suspense>
                        <Outlet/>
                    </Suspense>
                </Layout>
            ),
            loader: checkIsAuthenticated,
            children: [
                {
                    index: true,
                    element: <ProtectedRoute Component={<CustomerPage />} />
                },
                {
                    path: '/customer/:mode',
                    element: <ProtectedRoute Component={<AddEditCustomerPage />} />
                },
                {
                    path: '/customer/:mode/:id',
                    element: <ProtectedRoute Component={<AddEditCustomerPage />} />
                }
            ]
        },
        {
            path: '/proforma-invoice',
            element: (
                <Layout>
                    <Suspense>
                        <Outlet/>
                    </Suspense>
                </Layout>
            ),
            loader: checkIsAuthenticated,
            children: [
                {
                    index: true,
                    element: <ProtectedRoute Component={<ProformaInvoicePage />} />
                },
                {
                    path: '/proforma-invoice/:mode',
                    element: <ProtectedRoute Component={<AddEditProformaInvoicePage />} />
                },
                {
                    path: '/proforma-invoice/:mode/:id',
                    element: <ProtectedRoute Component={<AddEditProformaInvoicePage />} />
                }
            ]
        },
        {
            path: '/invoice-details',
            element: (
                <Layout>
                    <Suspense>
                        <Outlet/>
                    </Suspense>
                </Layout>
            ),
            loader: checkIsAuthenticated,
            children: [
                {
                    index: true,
                    element: <ProtectedRoute Component={<InvoiceDetailsPage />} />
                },
                {
                    path: '/invoice-details/:mode',
                    element: <ProtectedRoute Component={<AddEditInvoiceDetailsPage />} />
                },
                {
                    path: '/invoice-details/:mode/:id',
                    element: <ProtectedRoute Component={<AddEditInvoiceDetailsPage />} />
                }
            ]
        },
        {
            path: '/purchase-invoice',
            element: (
                <Layout>
                    <Suspense>
                        <Outlet/>
                    </Suspense>
                </Layout>
            ),
            loader: checkIsAuthenticated,
            children: [
                {
                    index: true,
                    element: <ProtectedRoute Component={<PurchaseInvoicePage />} />
                },
                {
                    path: '/purchase-invoice/:mode',
                    element: <ProtectedRoute Component={<AddEditPurchaseInvoicePage />} />
                },
                {
                    path: '/purchase-invoice/:mode/:id',
                    element: <ProtectedRoute Component={<AddEditPurchaseInvoicePage />} />
                }
            ]
        },
        {
            path: '/faq',
            element: (
                <Layout>
                    <Suspense>
                        <Outlet/>
                    </Suspense>
                </Layout>
            ),
            loader: checkIsAuthenticated,
            children: [
                {
                    index: true,
                    element: <ProtectedRoute Component={<FaqPage />} />
                },
                {
                    path: '/faq/:mode',
                    element: <ProtectedRoute Component={<AddEditFaqPage />} />
                },
                {
                    path: '/faq/:mode/:id',
                    element: <ProtectedRoute Component={<AddEditFaqPage />} />
                }
            ]
        },
        {
            path: '/login',
            element: !getAuthToken() ? (
                <Suspense>
                    <LoginPage/>
                </Suspense>
            ): (
                <Navigate to='/' replace={true} />
            )
        },
        {
            path: '404',
            element: <>< PageNotFound /></>

        },
        {
            path: 'not-authorized',
            element: <><Unauthorized /></>
        },
        {
            path: '*',
            element: <Navigate to='/404' replace />
        }
    ]);
    return <RouterProvider router={routes}></RouterProvider>
}

export default Routes;