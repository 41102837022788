import React, { FC } from 'react';
import './header.scss';
import NotificationIcon from '../../assets/images/icons/notification';
import profileImage from '../../assets/images/profileimage.png';
import MenuIcon from '../../assets/images/icons/menu';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

interface HeaderProps {
  activeTab: string;
  onMenuClick: () => void;
  fullName: string;
}

const Header: FC<HeaderProps> = ({ activeTab ,onMenuClick, fullName}) => {
    const handleMenuClick = () => {
        onMenuClick(); 
      };

      const loginUser = useSelector((state: RootState) => state.app.loginUser);

  return (
    <header className="header">
    <div className="header-left">
      <div onClick={handleMenuClick}>
      <MenuIcon className="menu-icon" />
      </div>
      <h1>{activeTab}</h1>
    </div>
    <div className="header-icons">
      <NotificationIcon />
      <img src={profileImage} alt="Profile" className="profile-image"  />
      {loginUser &&(
      <p className="user-name">{loginUser.fullName}</p> 
      )}
    </div>
  </header>
  );
};

export default Header;