import React, { MouseEventHandler } from 'react';
import "./button.scss";

interface ButtonProps {
  onClick?: MouseEventHandler<HTMLElement>;
  children: React.ReactNode; 
  className?: string; 
  disabled?: boolean; 
  type?: 'button' | 'submit' | 'reset'; 
  size?: 'large' | 'medium' | 'small' | 'null';
  variant?: 'text' | 'filled' | 'outlined';
  visible?: boolean; 
}

const Button: React.FC<ButtonProps> = ({
  onClick,
  children,
  className = '',
  disabled = false,
  type = 'button',
  size = 'medium',
 variant = 'filled',
 visible = true,
}) => {

  if (!visible) return null;

  return (
    <button
      type={type}
      onClick={onClick}
      className={`btn ${size} ${variant} ${className}`}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
