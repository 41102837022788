import axios from 'axios';
import {
  TOKEN_KEY,
  DEV_API_URL,
  PROD_API_URL
} from '../utils/constants';

export class ApiClient {
  client;
  constructor() {
    this.client = this.getCachedClient();
  }

  get BearerToken() {
    const token = localStorage.getItem(TOKEN_KEY);
    let bearer;

    if (token && token !== 'null' && token !== 'undefined') {
      bearer = token;
    } else {
      bearer = null;
    }

    return bearer;
  }

  get BaseUrl() {
    // if (
    //   window?.location.host.includes('localhost')
    //   // ||
    //   // window?.location.host.includes('admin-vbc.kuldeepakbari.com')
    // ) {
    //   return DEV_API_URL;
    // } else {
    //   return PROD_API_URL;
    // }
     const baseUrl = window?.location.host.includes('localhost') ? DEV_API_URL : PROD_API_URL;
    return `${baseUrl}`;
  }

  getCachedClient() {
    const axiosInstance = axios.create({
      baseURL: this.BaseUrl
    });

    axiosInstance.interceptors.request.use(
      (config) => {
        config.headers['x-api-key'] = process.env.REACT_APP_API_KEY;
        if (this.BearerToken) {
          //config.headers.Authorization = `Bearer ${JSON.parse(this.BearerToken)}`;
          config.headers.Authorization = this.BearerToken;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    // Added interceptor to handle 401 errors
    axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error?.response?.status === 401) {
          localStorage.removeItem(TOKEN_KEY);
          window.location.reload();
          // return bearer;
        }
        return Promise.resolve(error?.response);
      }
    );

    return axiosInstance;
  }

  async get(path: string, config = {}) {
    const response = await this.client.get(path, config);
    return response.data;
  }

  async post(path: string, data: any, config = {}) {
    const response = await this.client.post(path, data, config);
    return response.data;
  }

  async put(path: string, data: any, config = {}) {
    const response = await this.client.put(path, data, config);
    return response.data;
  }

  async patch(path: string, data: any, config = {}) {
    const response = await this.client.patch(path, data, config);
    return response.data;
  }
  async delete(path: string) {
    const response = await this.client.delete(path);
    return response.data;
  }

  async getXlsx(
    path: string,
    data: any,
    fileName = `frescor_${new Date().toDateString()}.xlsx`
  ) {
    const response = await this.client.post(path, data, {
      responseType: 'blob' //Force to receive data in a Blob Format
      // headers: {
      //   "x-api-key": "05646635804321276",
      //   Authorization: localStorage.getItem("token"),
      // }
    });

    if (response.status === 200) {
      //Create a Blob from the PDF Stream
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    } else {
      throw new Error(response.statusText);
    }
  }

  async getPdf(
    path: string,
    data: any,
    fileName = `frescor_${new Date().toDateString()}.pdf`
  ) {
    const response = await this.client.post(path, data, {
      responseType: 'blob' //Force to receive data in a Blob Format
      // headers: {
      //   "x-api-key": "05646635804321276",
      //   Authorization: localStorage.getItem("token"),
      // }
    });

    if (response.status === 200) {
      //Create a Blob from the PDF Stream
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    } else {
      throw new Error(response.statusText);
    }
  }
}
